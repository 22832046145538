<template>
  <div>
    <v-tabs class="mt-5" fixed-tabs v-model="tab" v-if="!verifyCode && !confirmationResult">
      <v-tab>{{ $t('email') }}</v-tab>
      <v-tab>{{ $t('phone') }}</v-tab>
    </v-tabs>
    <VuePhoneNumberInput
      class="my-10"
      default-country-code="DE"
      color="#F02121"
      error-color="#F02121"
      valid-color="#008FC7"
      :translations="phoneNumberInputTranslations"
      :disabled="confirmationResult !== null"
      v-model="phone"
      @update="onPhoneNumberUpdated"
      v-if="tab === 1" />
    <input class="my-10 w-100 input-normal" :placeholder="$t('email')" v-model="email" :disabled="verifyCode !== ''" v-else />
    <span v-if="waitTime > 0">{{ $t('resend_code_in', [getNumberWithZero(waitTime / 60), getNumberWithZero(waitTime % 60)]) }}</span>
    <div class="my-5 d-flex justify-content-center">
      <v-btn :class="`w-100 ${forLoginRegister ? 'button-empty' : 'button-normal'}`" @click="sendVerifyCode()" v-if="!verifyCode && !confirmationResult" :disabled="waitTime > 0">
        <span>{{ $t(`${forLoginRegister ? 'register' : 'next'}`) }}</span>
      </v-btn>
      <input class="input-normal font-bold font-20 text-center" style="letter-spacing: 10px;" v-model="inputedCode" maxlength="6" :oninput="onCodeInput()" v-else-if="!showResetPassword" />
    </div>
    <div class="d-flex flex-column" v-if="showResetPassword">
      <div class="mt-5 d-flex justify-content-between vertical-center" v-if="tab === 1">
        <span class="font-label">{{ $t('username') }}</span>
        <input class="w-75 input-normal" v-model="userName" />
      </div>
      <div class="mt-5 d-flex justify-content-between vertical-center">
        <span class="font-label">{{ $t('password') }}</span>
        <div class="w-75 d-flex justify-content-between vertical-center">
          <input class="w-100 input-normal" v-model="password" :type="showPassword ? 'text' : 'password'" />
          <v-btn icon @click="showPassword = !showPassword">
            <v-icon>{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="mt-5 d-flex justify-content-between vertical-center">
        <span class="font-label">{{ $t('repeat_password') }}</span>
        <div class="w-75 d-flex justify-content-between vertical-center">
          <input class="w-100 input-normal" v-model="passwordConfirm" :type="showPasswordConfirm ? 'text' : 'password'" />
          <v-btn icon @click="showPasswordConfirm = !showPasswordConfirm">
            <v-icon>{{ showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="my-5 d-flex justify-content-end">
        <v-btn class="button-normal" @click="resetPasswordTask()">
          <span class="px-10">{{ $t('save') }}</span>
        </v-btn>
      </div>
    </div>
    <div id="recaptcha-container" />
  </div>
</template>

<style lang="scss">
  @import "@/assets/sass/pages/auth.scss";
</style>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { firebaseAuth, functions } from '../../../main';
import { isValidEmail, showLoading, showFunctionError } from '../../../functions';

import TermsComponent from "@/view/pages/auth/TermsComponent.vue";

export default {
  name: 'VerifyEmailPhoneComponent',
  components: {
    VuePhoneNumberInput,
    TermsComponent
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  props: {
    forLoginRegister: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      phoneNumberInputTranslations: {
        countrySelectorLabel: this.$t('country_code'),
        phoneNumberLabel: this.$t('phone'),
        example: this.$t('example')
      },
      isResetPassword: this.$route.name === 'reset_password',
      tab: 0,
      waitTime: 0,
      email: '',
      phone: '',
      formattedNumber: '',
      recaptchaVerifier: null,
      confirmationResult: null,
      verifyCode: '',
      inputedCode: '',
      showResetPassword: false,
      userName: '',
      password: '',
      showPassword: false,
      passwordConfirm: '',
      showPasswordConfirm: false
    };
  },
  mounted() {
    const resendTime = localStorage.getItem('eventboxResendTime');
    if (resendTime) {
      this.waitTime = parseInt(resendTime) - parseInt(Date.now() / 1000);
      this.countDownTimer();
    }
    this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible'
    }, firebaseAuth);
  },
  methods: {
    getNumberWithZero(value) {
	    const s = '0' + parseInt(value);
	    return s.substring(s.length - 2);
    },
    countDownTimer() {
      if (this.waitTime > 0) {
        setTimeout(() => {
          this.waitTime--;
          this.countDownTimer();
        }, 1000);
      }
    },
    sendVerifyCode() {
      if (this.tab === 1) {
        if (this.formattedNumber) {
          if (this.isResetPassword) {
            this.sendPhoneVerifyCodeTask();
          } else {
            this.checkPhoneNumberTask();
          }
        } else {
          this.$toast.error(this.$t('please_enter') + this.$t('phone'));
        }
      } else {
        if (isValidEmail(this.email)) {
          this.sendEmailVerifyCodeTask();
        } else {
          this.$toast.error(this.$t('alert_invalid_email'));
        }
      }
    },
    onCodeSent() {
      localStorage.setItem('eventboxResendTime', parseInt(Date.now() / 1000) + 5 * 60);
      this.$emit('onVerifyCodeSent', !this.verifyCode && !this.confirmationResult ? '' : 'sent');
    },
    onPhoneNumberUpdated(data) {
      if (data.isValid && data.formattedNumber) {
        this.formattedNumber = data.formattedNumber;
      } else {
        this.formattedNumber = '';
      }
    },
    onCodeInput() {
      this.inputedCode = this.inputedCode.replace(/[^0-9]/g,'');
      if (this.inputedCode.length === 6) {
        if (this.tab === 1) {
          this.verifyPhoneCodeTask(this.inputedCode);
        } else {
          if (this.verifyCode === this.inputedCode) {
            if (this.isResetPassword) {
              this.onCodeVerified();
            } else {
              this.checkEmailTask();
            }
          } else {
            this.$toast.error(this.$t('alert_incorrect_verification_code'));
          }
        }
      }
    },
    onCodeVerified() {
      if (this.isResetPassword) {
        this.showResetPassword = true;
      } else {
        const userInfo = {
          email: this.email,
          phone: this.formattedNumber,
          userName: '',
          userType: localStorage.getItem('eventboxUserType')
        };
        localStorage.setItem('eventboxRegisterUser', JSON.stringify(userInfo));
        this.$router.push(`register`);
      }
    },
    sendEmailVerifyCodeTask() {
      const params = {
        email: this.email
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'sendVerificationCode');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        const code = response.data;
        if (code) {
          window.alert(this.$t('alert_sent_verification_code'));
          this.verifyCode = code.toString();
          this.onCodeSent();
        } else {
          this.$toast.error(this.$t('alert_failed_to_send_confirm_email', [this.email]));
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    checkEmailTask() {
      const params = {
        fieldPath: 'email',
        value: this.email,
        password: ''
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'loginUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_EMAIL_NOT_EXIST) {
          this.onCodeVerified();
        } else {
          this.$toast.error(this.$t('alert_email_already_exist'));
          this.$router.push(`login`);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    checkPhoneNumberTask() {
      const params = {
        checkPhoneNumber: true,
        phone: this.formattedNumber
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'registerUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === true) {
          this.sendPhoneVerifyCodeTask();
        } else {
          this.$toast.error(this.$t('alert_over_max_account_per_phone'));
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    sendPhoneVerifyCodeTask() {
      if (!this.recaptchaVerifier) {
        this.$toast.error(this.$t('alert_unexpected_error'));
        return;
      }
      const loader = showLoading(this, 1);
      signInWithPhoneNumber(firebaseAuth, this.formattedNumber, this.recaptchaVerifier).then(result => {
        loader.hide();
        this.confirmationResult = result;
        this.onCodeSent();
      }).catch(error => {
        loader.hide();
        this.$toast.error(`${error.code} - ${error.message}`);
      });
    },
    verifyPhoneCodeTask(code) {
      if (!this.confirmationResult) {
        this.$toast.error(this.$t('alert_unexpected_error'));
        return;
      }
      const loader = showLoading(this, 1);
      this.confirmationResult.confirm(code).then(result => {
        loader.hide();
        const user = result.user;
        if (user && user.phoneNumber) {
          this.formattedNumber = user.phoneNumber;
          this.onCodeVerified();
        } else {
          this.$toast.error(this.$t('alert_unexpected_error'));
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(`${error.code} - ${error.message}`);
      });
    },
    resetPasswordTask() {
      if (this.tab === 1 && !this.userName) {
        this.$toast.error(this.$t('please_enter') + this.$t('username'));
        return;
      }
      if (this.password.length < 6) {
        this.$toast.error(this.$t('alert_min_characters', [6, this.$t('password')]));
        return;
      }
      if (this.password !== this.passwordConfirm) {
        this.$toast.error(this.$t('alert_passwords_not_match'));
        return;
      }
      const md5 = require('md5');
      const params = {
        resetPassword: true,
        newPassword: md5(this.password)
      };
      if (this.tab === 1) {
        params['fieldPath'] = 'userName';
        params['value'] = this.userName;
        params['phone'] = this.formattedNumber;
      } else {
        params['fieldPath'] = 'email';
        params['value'] = this.email;
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'updateUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('alert_success'));
          this.$router.push(`login`);
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>